import React, { forwardRef, useEffect, useReducer } from 'react'
import styled from 'styled-components'
import ItemStep from './itemStep'
import { useSelector } from 'react-redux'
import { RootState } from 'store/'

export type Props = {
  onItemStepClickedListenerProp?: (step: number) => void
  isFormComplete?: boolean
}

const FormStepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 46.0625rem;
  height: 100%;
  box-sizing: border-box;
  border-radius: 0.625rem;
  border: 1px solid #fff;
  @media screen and (max-width: 870px) {
    width: 10.8125rem;
    height: 2.8125rem;
    padding: 0 1rem;
  }

`

type Action = {
  type: string,
  payload: { [index: number]: boolean };
}

const FormStep: React.FC<Props> = (props) => {

  let currentSteps = useSelector((state: RootState) =>
    state.FormHighOrder.formSteps)

  let onPage = useSelector((state: RootState) =>
    state.FormHighOrder.onPage)

  const { onItemStepClickedListenerProp, isFormComplete } = props

  return (
    <>

      <FormStepContainer>
        <ItemStep
          idActive={isFormComplete ? 4 : onPage}
          id={0}
          title={'Your work'}
          changeIdActive={() => {
            onItemStepClickedListenerProp ? onItemStepClickedListenerProp(0) : {}
          }}
          clickableProp={currentSteps[0].previousStepsValid}
          isDisable={!isFormComplete}
        />

        <ItemStep
          idActive={isFormComplete ? 4 : onPage}
          id={1}
          title={'About you'}
          changeIdActive={() => {
            onItemStepClickedListenerProp ? onItemStepClickedListenerProp(1) : {}
          }}
          clickableProp={currentSteps[1].previousStepsValid && currentSteps[0].hasSuccessfullyPosted}
          isDisable={!isFormComplete}
        />

        {/**
         * total Width size = iconW + marginRight + textW
         */}
        <ItemStep
          widthSize='14rem'
          idActive={isFormComplete ? 4 : onPage}
          id={2}
          title={'Membership agreement'}
          changeIdActive={() => {
            onItemStepClickedListenerProp ? onItemStepClickedListenerProp(2) : {}
          }}
          clickableProp={currentSteps[2].previousStepsValid && currentSteps[1].hasSuccessfullyPosted}
          isDisable={!isFormComplete}
        />

        {/**
         * total Width size = iconW + marginRight + textW
         */}
        <ItemStep
          widthSize='11.1875rem'
          idActive={isFormComplete ? 4 : onPage}
          id={3}
          title={'Your contribution'}
          changeIdActive={() => {
            onItemStepClickedListenerProp ? onItemStepClickedListenerProp(3) : {}
          }}
          clickableProp={currentSteps[3].previousStepsValid && currentSteps[2].hasSuccessfullyPosted}
          isDisable={!isFormComplete}
        />
      </FormStepContainer>

    </>
  )
}

export default FormStep
