import React, { useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import animationData from './data.json'
import Lottie from 'react-lottie'
import color from '../color-css/color'

export type Props = {
  idActive: number
  id: number
  changeIdActive: () => void
  title: string
  widthSize?: string
  clickableProp: boolean
  isDisable?: boolean
}

const handleClickableEl = (clickable: boolean) => {
  if (clickable)
    return `cursor: pointer;\n`
      + 'color: #00AFD9 !important;\n'
      + 'opacity: 1 !important'
  return ''
    + 'cursor: not-allowed;\n'
    + 'pointer-events: none;'
}


interface StyledCounter {
  widthSize?: string
  hover: boolean
  isClickable: boolean
  isDisable?: boolean
}

const StepIndexWrapper = styled.button<StyledCounter>`
  width: ${props => props.widthSize ? props.widthSize : '7.5rem'};
  margin: auto 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0;
  ${({ isClickable }) => handleClickableEl(isClickable)};
  background: none;
  border: none;
  outline: none;
  transition: all 0.5s ease-in-out;
  @media screen and (max-width: 870px) {
    border: 0;
    width: 1.875rem;
  }
  pointer-events: ${props => props.isDisable ? '' : 'none'};
  &:active {
    transition: all 0.5s ease-in-out;
  }
`

const StyledNum = styled.div`
  width: 1.75rem;

`
interface StyledNumSpanInterface {
  idNumber: number
  idActive: number
  hover: boolean
  isClickable: boolean
}
// @ts-ignore
const StyledNumSpan = styled.span<StyledNumSpanInterface>`
  width: ${props => props.idNumber <= props.idActive ? '1.875rem' : '1.75rem'};
  height: ${props => props.idNumber <= props.idActive ? '1.875rem' : '1.75rem'};
  line-height: ${props => props.idNumber <= props.idActive ? '1.875rem' : '1.625rem'};
  font-size: 1rem;
  text-align: center;
  ${({ isClickable }) => handleClickableEl(isClickable)};
  color: ${props => props.idNumber <= props.idActive ? '#00AFD9' : props.hover ? '#00AFD9' : '#757575'};
  background: ${props => props.idNumber <= props.idActive ? '#E2F1F8' : props.hover ? '#E2F1F8' : '#F3F3F3'};
  border-radius: 0.875rem;
  border: 1px solid ${props => props.idNumber <= props.idActive ? '#E2F1F8' : props.hover ? '#E2F1F8' : '#F3F3F3'};
  display: block;
  font-weight: 500;
  font-size: 1rem;
  font-family: Intro, museo-sans, sans-serif ;
  opacity: ${props => props.idNumber <= props.idActive ? '1' : '0.5'};
  &:focus {
    outline: blue;
    transition: all 0.5s ease-in-out;
    color: ${props => props.idNumber <= props.idActive ? '#00AFD9' : props.hover ? '#00AFD9' : '#757575'};
    background: ${props => props.idNumber <= props.idActive ? '#E2F1F8' : props.hover ? '#E2F1F8' : '#F3F3F3'};
  }
  @media screen and (max-width: 870px) {
    line-height: 1.625rem;
    width: 1.75rem;
    height: 1.75rem;
    color: ${props => props.idNumber <= props.idActive ? '#012F57' : props.hover ? '#00AFD9' : '#757575'};
  }
 `
const fadeIn = keyframes`
  from {
    transform: scale(.25);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`

const fadeOut = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(.25);
    opacity: 0;
  }
`

const StyledNumSpan2 = styled.span<StyledNumSpanInterface>`
  display: ${props => props.hover ? 'block' : 'none'};
  width: ${props => props.idNumber <= props.idActive ? '1.875rem' : '1.75rem'};
  height: ${props => props.idNumber <= props.idActive ? '1.875rem' : '1.75rem'};
  line-height: ${props => props.idNumber <= props.idActive ? '1.875rem' : '1.625rem'};
  font-size: 1rem;
  text-align: center;
  color: ${props => props.idNumber <= props.idActive ? '#00AFD9' : props.hover ? '#00AFD9' : '#757575'};
  background: ${props => props.idNumber <= props.idActive ? '#E2F1F8' : props.hover ? '#E2F1F8' : '#F3F3F3'};
  border-radius: 0.875rem;
  border: 1px solid #e2f1f8;
  font-weight: 800;
  font-size: 1rem;
  font-family: Intro, museo-sans, sans-serif ;
  visibility: ${props => props.idNumber >= props.idActive ? 'hidden' : !props.hover ? 'hidden' : 'visible'};
  animation: ${props => !props.hover ? fadeOut : fadeIn} 0.2s linear;
  transition: visibility 0.2s linear;
  &:focus {
    outline: blue;
    transition: all 0.5s ease-in-out;
    color: ${props => props.idNumber <= props.idActive ? '#00AFD9' : props.hover ? '#00AFD9' : '#757575'};
    background: ${props => props.idNumber <= props.idActive ? '#E2F1F8' : props.hover ? '#E2F1F8' : '#F3F3F3'};
  }
  @media screen and (max-width: 870px) {
    line-height: 1.625rem;
    width: 1.75rem;
    height: 1.75rem;
    color: ${props => props.idNumber <= props.idActive ? '#012F57' : props.hover ? '#00AFD9' : '#757575'};
  }
 `
const StyledDesc = styled.div`
  margin-left: 0.4375rem;
  line-height: 1.75rem;
  color: #012e56;
  text-align: left;
`
interface StyledTitleInterface {
  idNumber: number
  idActive: number
  hover: boolean
  isMobile: boolean
}

const StyledTitle = styled.span<StyledTitleInterface>`
  text-align: left;
  font-size: 1rem;
  font-family: museo-sans,sans-serif;
  font-weight: ${props => (props.idNumber <= props.idActive) && props.hover === false ? '900' : props.hover ? '700' : '500'};
  letter-spacing: -0.05px;
  color: ${props => props.idNumber <= props.idActive ? '#012F57' : props.hover ? '#012F57' : '#757575'}};
  opacity: ${props => props.idNumber <= props.idActive ? '1' : '0.5'};
  display: inline;
  outline: 0;
  @media screen and (max-width: 870px) {
    display: none;
  }
  white-space: nowrap;
`

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
}

export const ItemStep: React.FC<Props> = (props) => {
  const { idActive, id, changeIdActive, title, widthSize, clickableProp, isDisable } = props
  const [hover, changeHover] = React.useState(false)
  const [isMobile, changeIsMobile] = React.useState(false)
  const [isStopped, changeIsStopped] = React.useState(false)

  useEffect(() => {
    changeIsMobile(typeof window !== 'undefined' && window.innerWidth < 768 ? true : false)
    // @ts-ignore
    window.addEventListener('resize', changeIsMobile(typeof window !== 'undefined' && window.innerWidth < 768 ? true : false))
    // @ts-ignore
    return window.removeEventListener('resize', changeIsMobile(typeof window !== 'undefined' && window.innerWidth < 768 ? true : false))
  }, [])

  useEffect(() => {
    isMobile && changeHover(false)
  }, [isMobile])

  return (
    <StepIndexWrapper
      // @ts-ignore
      onClick={() => {
        if (isDisable === true) changeIdActive()
      }}
      onFocus={() => clickableProp === true && isMobile === false && changeHover(true)}
      onBlur={() => clickableProp === true && changeHover(false)}
      onMouseEnter={() => isMobile === false && changeHover(true)}
      onMouseLeave={() => changeHover(false)}
      onKeyDown={(e) => {
          if (e.key === 'Enter' && clickableProp === true && isDisable === true) {
            changeIdActive()
          } else if (e.key === 'Enter') {
            e.preventDefault()
          }
        }
      }
      widthSize={widthSize}
      hover={hover}
      isClickable={clickableProp}
      isDisable={isDisable}
    >
      <StyledNum>
        <div style={{ position: 'relative' }}>
          {
            idActive > id ? (
              <Lottie
                options={defaultOptions}
                height='2.125rem'
                width='2.125rem'
                isStopped={isStopped}
                speed={0.75} />
            )
              : (
                <StyledNumSpan
                  isClickable={clickableProp}
                  idActive={idActive}
                  idNumber={id}
                  hover={hover} >
                  {id + 1}
                </StyledNumSpan>
              )}

          <div style={{ position: 'absolute', top: '1px', left: '1px' }}>
            <StyledNumSpan2
              isClickable={clickableProp}
              idActive={idActive}
              idNumber={id}
              hover={hover} >
              {id + 1}
            </StyledNumSpan2>
          </div>
        </div>
      </StyledNum>
      <StyledDesc>
        <StyledTitle
          //@ts-ignore
          idActive={idActive}
          idNumber={id}
          hover={hover}
          isMobile={isMobile}
        >
          {title}
        </StyledTitle>
      </StyledDesc>
    </StepIndexWrapper>
  )
}

export default ItemStep
